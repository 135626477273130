import * as React from 'react';
import { indexOf, uniq, values } from 'ramda';
import { styled } from '@mui/system';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ClearIcon from '@mui/icons-material/Clear';

import colors from '../../../../../../../config/theme/colors';

import ActionButton from '../../../../../../../components/ActionButton';
import { Container, Item } from '../../../../../../../components/Grid';
import InputHandler from '../../../../../../../components/InputHandler';

import { nowTime } from 'neuro-utils';
import MedicationUnit from 'Routes/Medication/utils/medicationUnit';

const ClearIconStyled = styled(ClearIcon)({
  display: 'block',
  width: '3rem',
  color: colors.secondaryText,
  cursor: 'pointer',
  '&:hover': {
    color: 'red',
  },
});

const Row = ({ formData, regimenIndex, availableStrengths, dosage }: IRowProps): JSX.Element => {
  const { document, onChange } = formData as IFormData<IMedication>;

  const getRegimens = (doc: IMedication): TArrayWithID<IRegimenBasics & IRegimenDefault> =>
    ((doc as IMedication).regimen?.map((r) => r) as TArrayWithID<IRegimenBasics & IRegimenDefault>) ?? [];

  const getDosages = (doc: IMedication): Array<IDosage> => getRegimens(doc)?.[regimenIndex].dosages ?? [];

  const getStrengths = (): Array<number> => {
    const thisRegimen = getRegimens(document)[regimenIndex];
    return thisRegimen.strengths
      ? uniq(
          thisRegimen.strengths
            .map((d: IStrengths) => indexOf(d, availableStrengths))
            .sort((a: number, b: number) => a - b),
        )
      : [];
  };

  const deleteDosage = (): void => {
    const regimenArr = getRegimens(document);
    if (regimenArr) {
      const dosageArr = getDosages(document).filter((d) => d.id !== dosage.id);

      regimenArr[regimenIndex] = { ...regimenArr[regimenIndex], dosages: dosageArr };
      onChange?.({ regimen: regimenArr });
    }
  };

  /** Initialize the row's `from` or `to` field's time value. */
  const addTime = (time: 'from' | 'to') => (): void => {
    const regimenArr = getRegimens(document);
    /** Default time value for field `from`. */
    const timeDefaultFrom: Time = [8, 0];
    if (regimenArr) {
      const dosages = getDosages(document).map((d) =>
        d.id === dosage.id ? { ...d, [time]: time === 'from' ? timeDefaultFrom : nowTime() } : d,
      );

      regimenArr[regimenIndex] = { ...regimenArr[regimenIndex], dosages };

      onChange?.({ regimen: regimenArr });
    }
  };

  const getStrengthIndex = (s: number): string => {
    const strength = availableStrengths[s];
    const idx = values(strength).join('/');
    return idx;
  };

  const changeTime =
    (name: string) =>
    (values: TOnChangeValues): void => {
      const value = values[name];
      if (value) {
        const regimenArr = getRegimens(document);
        if (regimenArr) {
          const modifiedDosage = { ...dosage, [name]: value };
          const dosages = getDosages(document).map((d) => (d.id === dosage.id ? modifiedDosage : d));

          regimenArr[regimenIndex] = { ...regimenArr[regimenIndex], dosages };

          onChange?.({ regimen: regimenArr });
        }
      }
    };

  const changeStrength =
    (s: number) =>
    (values: TOnChangeValues): void => {
      const name = Object.keys(values)[0];
      const value = values[name];

      const regimenArr = getRegimens(document);
      if (regimenArr) {
        const modifiedDosages = { ...dosage.dosages, [getStrengthIndex(s)]: value };

        const modifiedDosage = { ...dosage, dosages: modifiedDosages };
        const dosages = getDosages(document).map((d) => (d.id === dosage.id ? (modifiedDosage as IDosage) : d));

        regimenArr[regimenIndex] = { ...regimenArr[regimenIndex], dosages };
        onChange?.({ regimen: regimenArr });
      }
    };

  const unit = (getRegimens(document)?.[regimenIndex] as IRegimenDefault).unit;

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <Container alignItems="center">
          <Item>
            <InputHandler
              type="TimePicker"
              name="from"
              formData={{
                document: { from: dosage.from },
                onChange: changeTime('from'),
              }}
              editing={true}
              width={12}
            />
          </Item>

          {<Item style={{ padding: '0 1rem' }}>{dosage?.to ? '-' : ''}</Item>}
          {dosage?.to ? (
            <Item>
              <InputHandler
                type="TimePicker"
                name="to"
                formData={{
                  document: { to: dosage.to },
                  onChange: changeTime('to'),
                }}
                editing={true}
                width={12}
              />
            </Item>
          ) : dosage.from ? (
            <Item>
              <ActionButton
                text={'medication.doses.endTime'}
                onClick={addTime('to')}
                width={10}
                height={3}
                fontSize={14}
              />
            </Item>
          ) : (
            ''
          )}
        </Container>
      </TableCell>
      {getStrengths().map((s) => (
        <React.Fragment key={s}>
          <TableCell>
            <InputHandler
              type="NumberField"
              name="dosage"
              formData={{
                document: { dosage: dosage?.dosages?.[getStrengthIndex(s)] ?? '' },
                onChange: changeStrength(s),
              }}
              editing={true}
              width={5}
              precision={2}
            />
            <MedicationUnit unit={unit} />
          </TableCell>
        </React.Fragment>
      ))}
      <TableCell>
        <ClearIconStyled onClick={deleteDosage} />
      </TableCell>
    </TableRow>
  );
};

interface IRowProps {
  formData: IFormData<IMedication>;
  regimenIndex: number;
  availableStrengths: Array<IStrengths>;
  dosage: IDosage;
}

export default Row;
