import * as React from 'react';

import DocumentCreationButton from 'Components/DocumentCreationButton';
import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import FormEditingHandler from '../../../containers/FormEditingHandler';

import RTMSForm from './Form';
import RTMSHistory from './HistoryRowData';
import { useIntl } from 'react-intl';
import { sortPartialDate } from 'neuro-utils';
import DoctorsOrdersReview from './Form/Sessions/components/DoctorsOrdersReview';
import { useAppSelector } from 'Store/index';

export const RTMSContext = React.createContext<IRTMSContext>({
  fm: (m: string) => m,
  isEditing: false,
  formData: {
    document: { _id: 'temp-id', _cid: 'temp-cid', _type: 'temp-type', _cdate: 0, _editing: false, _docCreateDate: 0 },
    onChange: () => {
      return;
    },
  },
  startEdit: (d: { _id?: string }) => (e: React.MouseEvent<Element, MouseEvent>) => {
    !!d;
    !!e;
    return;
  },
  documents: [],
  /** Doctors orders with RTMS in current treatment period */
  doctorsOrders: [],
  /** Other doctors orders in current treatment period */
  otherDoctorsOrders: [],
  /** Doctors orders from every treatment period */
  allDoctorsOrders: [],
  ninmtTreatmentPeriods: [],
  contraIndications: [],
  symptomsAndLocations: [],
  doctorsOrdersReview: { active: false, completed: false },
  setDoctorsOrdersReview: () => '',
  anchor: undefined,
  setAnchor: () => '',
  /** For treatment efficiency functionalities */
  patientMySQStatus: undefined,
  /** For treatment efficiency functionalities */
  taskLists: [],
  /** For treatment efficiency functionalities */
  sortedAndMergedMyDocuments: [],
});

interface IRTMSContext {
  fm: (m: string) => string;
  isEditing: boolean;
  formData: IFormData<IRTMS>;
  documents: Array<IRTMS>;
  doctorsOrders: Array<IDoctorsOrder>;
  otherDoctorsOrders: Array<IDoctorsOrder>;
  allDoctorsOrders: Array<IDoctorsOrder>;
  ninmtTreatmentPeriods: Array<ININMTTreatmentPeriod>;
  contraIndications: Array<IContraIndicationToTreatment>;
  symptomsAndLocations: Array<ISymptomsAndLocations>;
  doctorsOrdersReview: { active: boolean; completed: boolean };
  setDoctorsOrdersReview: React.Dispatch<
    React.SetStateAction<{
      active: boolean;
      completed: boolean;
    }>
  >;
  anchor: string | undefined;
  setAnchor: React.Dispatch<React.SetStateAction<string | undefined>>;
  startEdit: (document: { _id?: string }) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  patientMySQStatus: string | null | undefined;
  taskLists: ITaskList[];
  sortedAndMergedMyDocuments: ({ [key: string]: any } & IControlProps)[];
}

const RTMS = ({ documents }: IOwnProps): JSX.Element => {
  const patientMySQStatus = useAppSelector((s: IState) => s.myapp.mysqUserId);
  const taskLists = useAppSelector((s: IState) => s.myapp.taskLists);
  const sortedAndMergedMyDocs = useAppSelector((s: IState) => s.myapp.sortedAndMergedDocuments) || [];

  const [doctorsOrdersReview, setDoctorsOrdersReview] = React.useState<{ active: boolean; completed: boolean }>({
    active: false,
    completed: false,
  });

  // Anchor for scrolling to specific sections in form
  const [anchor, setAnchor] = React.useState<string | undefined>(undefined);

  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });
  const ninmtTreatmentPeriodDocs: Array<ININMTTreatmentPeriod> = documents.filter(
    (d) => d._type === 'ninmtTreatmentPeriod',
  );

  let doctorsOrdersDocs: Array<IDoctorsOrder> = [];
  let otherDoctorsOrdersDocs: Array<IDoctorsOrder> = [];
  let allDoctorsOrdersDocs: Array<IDoctorsOrder> = [];
  let contraIndicationsDocs: Array<IContraIndicationToTreatment> = [];
  let symptomsAndLocationsDocs: Array<ISymptomsAndLocations> = [];
  let latestOrderWithRTMS: IDoctorsOrder;

  if (Array.isArray(ninmtTreatmentPeriodDocs) && ninmtTreatmentPeriodDocs.length > 0) {
    const latestTreatmentPeriodDate = ninmtTreatmentPeriodDocs.sort((n1, n2) => sortPartialDate(n2.date, n1.date))[0]
      .date;

    allDoctorsOrdersDocs = documents.filter(
      (d) =>
        d._type === 'doctorsOrders' &&
        // Only doctor's orders with RTMS are relevant
        Array.isArray((d as IDoctorsOrder).treatmentType) &&
        (d as IDoctorsOrder).treatmentType?.includes('rtms'),
    );

    doctorsOrdersDocs = allDoctorsOrdersDocs.filter((d) => sortPartialDate(d.date, latestTreatmentPeriodDate) >= 0);
    latestOrderWithRTMS = doctorsOrdersDocs.slice().sort((d1, d2) => sortPartialDate(d2.date, d1.date))[0];

    otherDoctorsOrdersDocs = documents.filter(
      (d: IDoctorsOrder) =>
        d._type === 'doctorsOrders' &&
        sortPartialDate(d.date, latestTreatmentPeriodDate) >= 0 &&
        (!Array.isArray(d.treatmentType) || d.treatmentType?.length === 0) &&
        Object.keys(d).some((k) => ['nextInterview', 'ninmtMeeting', 'requestFollowUp'].includes(k)),
    );

    contraIndicationsDocs = documents.filter(
      (d) => d._type === 'contraIndicationsToTreatment' && sortPartialDate(d.date, latestTreatmentPeriodDate) >= 0,
    ) as IContraIndicationToTreatment[];

    symptomsAndLocationsDocs = documents.filter(
      (d) =>
        d._type === 'symptomsAndLocations' &&
        sortPartialDate((d as ISymptomsAndLocations).startDate, latestTreatmentPeriodDate) >= 0,
    ) as ISymptomsAndLocations[];
  }

  const parsedDocs = JSON.parse(JSON.stringify(documents));
  const rtmsDocs: Array<IRTMS> = parsedDocs
    .filter((d: IRTMS) => d._type === 'rtms')
    .map((d: IRTMS) => {
      if (Array.isArray(d.sessions) && d.sessions.length > 0) {
        d.sessions = d.sessions?.filter((s) => !(s.removeTS || s.removeInfo));
      }
      return d;
    });

  // Track editing status of documents
  const isEditing = rtmsDocs.some((d) => d._editing);

  // If not editing any docs, reset doctorsOrdersReview status if active
  React.useEffect(() => {
    if (!isEditing && doctorsOrdersReview.active) {
      setDoctorsOrdersReview({ active: false, completed: false });
    }
  }, [isEditing]);

  // Scroll to top when starting review of doctor's orders
  React.useEffect(() => {
    if (doctorsOrdersReview.active) {
      scrollTo(0, 0);
    } else {
      // Reset anchor when review is done
      setTimeout(() => setAnchor(undefined), 600);
    }
  }, [doctorsOrdersReview.active]);

  return (
    <FormEditingHandler
      name="rtms"
      documents={rtmsDocs}
      toolbarProps={{
        saveButtonDisabled: doctorsOrdersReview.active,
        cancelButtonDisabled: doctorsOrdersReview.active,
      }}
    >
      {(editing, startEdit, formData, view): JSX.Element => (
        <RTMSContext.Provider
          value={{
            fm: fm,
            isEditing: !!editing && !view?.viewing,
            formData: formData,
            documents: rtmsDocs,
            doctorsOrders: doctorsOrdersDocs,
            otherDoctorsOrders: otherDoctorsOrdersDocs,
            allDoctorsOrders: allDoctorsOrdersDocs,
            ninmtTreatmentPeriods: ninmtTreatmentPeriodDocs,
            contraIndications: contraIndicationsDocs,
            symptomsAndLocations: symptomsAndLocationsDocs,
            doctorsOrdersReview: doctorsOrdersReview,
            setDoctorsOrdersReview: setDoctorsOrdersReview,
            anchor: anchor,
            setAnchor: setAnchor,
            startEdit: startEdit,
            patientMySQStatus: patientMySQStatus,
            taskLists: taskLists,
            sortedAndMergedMyDocuments: sortedAndMergedMyDocs,
          }}
        >
          <DocumentWrapper>
            <DocumentHeader
              name="rtms"
              headerId={doctorsOrdersReview.active ? 'rtms.doctorsOrdersReview' : 'rtms.title'}
              headerWidth={9}
              infoText={doctorsOrdersReview.active ? 'rtms.uncompletedDoctorsOrderMessage' : undefined}
              editing={editing}
              editButtons={
                <DocumentCreationButton
                  name="rtms"
                  text="rtms.newRtms"
                  onClick={(e: React.MouseEvent): void => {
                    startEdit({}, 'rtms')(e);
                  }}
                  disabled={rtmsDocs.some((d) => !d.hasEnded) || latestOrderWithRTMS?.rtms?.endRTMS}
                  disabledTooltip={
                    latestOrderWithRTMS?.rtms?.endRTMS ? (
                      <span>{fm('rtms.unableToAddNew')}</span>
                    ) : (
                      <span>{fm('rtms.endOngoingToAddNew')}</span>
                    )
                  }
                />
              }
            />
            {editing ? (
              <React.Fragment>
                <div style={{ display: doctorsOrdersReview.active ? 'none' : undefined }}>
                  <RTMSForm />
                </div>
                <div style={{ display: doctorsOrdersReview.active ? undefined : 'none' }}>
                  <DoctorsOrdersReview />
                </div>
              </React.Fragment>
            ) : null}
            {!editing && !view?.viewing ? <RTMSHistory /> : null}
          </DocumentWrapper>
        </RTMSContext.Provider>
      )}
    </FormEditingHandler>
  );
};

interface IOwnProps {
  documents: Array<IRTMS | IDoctorsOrder | IContraIndicationToTreatment>;
}

export default RTMS;
