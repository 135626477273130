import DocumentCreationButton from 'Components/DocumentCreationButton';
import { Item } from 'Components/Grid';
import HistoryRowControls from 'Components/HistoryRowControls';
import HistoryRowListing from 'Components/HistoryRowListing';
import HistorySection from 'Components/HistorySection';
import { formatPartialDate } from 'neuro-utils';
import * as React from 'react';
import { useIntl } from 'react-intl';

const ProceduresHistory = ({ documents, startEdit, setProcedureCode }: IOwnProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  return (
    <>
      <HistorySection
        headerText={fm('procedure.plasmaChange')}
        newButton={
          <DocumentCreationButton
            name="procedure"
            text={'procedure.newPlasmaChange'}
            onClick={(e: React.MouseEvent): void => {
              setProcedureCode?.('19647005');
              startEdit({} as IProcedure, 'procedure')(e);
            }}
            width={15}
            height={3}
            fontSize={14}
            alternate={true}
          />
        }
      >
        <HistoryRowListing
          documents={documents?.filter((d) => d?.code?.code === '19647005')}
          headers={
            <>
              <Item xs={3}>{fm('general.date')}</Item>
              <Item xs={9}>{fm('procedure.additionalInformation')}</Item>
            </>
          }
          contentFormat={(d: IProcedure): JSX.Element => (
            <>
              <Item xs={3} style={{ fontWeight: '600' }}>
                {formatPartialDate(d?.date ?? undefined)}
              </Item>
              <Item xs={6}>{d?.additionalInformation || '-'}</Item>
              <Item xs={3}>
                <HistoryRowControls
                  document={d}
                  startEdit={(d) => (e) => {
                    setProcedureCode?.('19647005');
                    startEdit(d)(e);
                  }}
                />
              </Item>
            </>
          )}
        />
      </HistorySection>
      <HistorySection
        headerText={fm('procedure.thymectomy')}
        newButton={
          <DocumentCreationButton
            name="procedure"
            text={'procedure.newThymectomy'}
            onClick={(e: React.MouseEvent): void => {
              setProcedureCode?.('399750009');
              startEdit({} as IProcedure, 'procedure')(e);
            }}
            width={15}
            height={3}
            fontSize={14}
            alternate={true}
          />
        }
      >
        <HistoryRowListing
          documents={documents?.filter((d) => d?.code?.code === '399750009')}
          headers={
            <>
              <Item xs={3}>{fm('general.date')}</Item>
              <Item xs={9}>{fm('procedure.additionalInformation')}</Item>
            </>
          }
          contentFormat={(d: IProcedure): JSX.Element => (
            <>
              <Item xs={3} style={{ fontWeight: '600' }}>
                {formatPartialDate(d?.date ?? undefined)}
              </Item>
              <Item xs={6}>{d?.additionalInformation || '-'}</Item>
              <Item xs={3}>
                <HistoryRowControls
                  document={d}
                  startEdit={(d) => (e) => {
                    setProcedureCode?.('399750009');
                    startEdit(d)(e);
                  }}
                />
              </Item>
            </>
          )}
        />
      </HistorySection>
    </>
  );
};

interface IOwnProps {
  documents: Array<IProcedure>;
  startEdit: (document: IProcedure, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  setProcedureCode?: React.Dispatch<React.SetStateAction<string>>;
}

export default ProceduresHistory;
