import * as React from 'react';
import { EventStepperComponent } from '.';
import { IEventStepper, IEventStepperGroup } from './definitions';
import { omit } from 'Utility/ramdaReplacement';

const EventStepperGroup = ({
  children,
  previousEventsCollapseLimit,
  previousEventsCollapseMessage,
  allClosed,
}: IEventStepperGroup): JSX.Element => {
  const namesAndProps = children.map((child) => ({ name: child.props.name, props: omit(['name'], child.props) }));

  const propsByType = namesAndProps.reduce(
    (current, key) => {
      (current as { [key: string]: any })[key.name] = key.props;
      return current;
    },
    {} as { [key: string]: IEventStepper },
  );

  if (Object.keys(propsByType).length === 0) return <></>;

  if (previousEventsCollapseLimit) {
    propsByType[Object.keys(propsByType)[0]].previousEventsCollapseLimit = previousEventsCollapseLimit;
    propsByType[Object.keys(propsByType)[0]].previousEventsCollapseMessage = previousEventsCollapseMessage ?? {
      showMessage: 'general.show',
      hideMessage: 'general.hide',
    };
  }
  if (allClosed) {
    propsByType[Object.keys(propsByType)[0]].allClosed = allClosed;
  }

  // Combine events of different types to one array
  const combinedEvents: any[] = Object.keys(propsByType)
    // _type property is internal to EventStepper, used to distinct different types of events
    .map((n) => propsByType[n].formData.document?.[n]?.map((e: any) => ({ ...e, _type: n })))
    .flat()
    .filter((e) => e);

  return <EventStepperComponent type="group" propsByType={propsByType} combinedEvents={combinedEvents} />;
};

export default EventStepperGroup;
