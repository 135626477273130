import * as React from 'react';

import EventStepper from '../../../../../components/EventStepper';
import { fm } from 'Components/FormatMessage';
import InputHandler from '../../../../../components/InputHandler';
import SelectByCategory from '../../../../../components/InputHandler/components/SelectByCategory';

import colors from '../../../../../config/theme/colors';
import { formatPartialDate, partialDateToValue, sortPartialDate } from 'neuro-utils';
import {
  backwardsCompatibility,
  generatorDateHook,
  generatorSettingHandler,
  generatorsUnavailable,
} from '../../../utils';
import GeneratorDetails from '../GeneratorDetails';
import ConfirmationDialog from 'Components/ConfirmationDialog';

const optionFormatter = (opt: string | number): JSX.Element | string => {
  return opt === 'Other rechargeable'
    ? fm('dbs.otherRechargeable')
    : opt === 'Other battery'
      ? fm('dbs.otherPrimaryCell')
      : typeof opt === 'number'
        ? JSON.stringify(opt)
        : opt;
};

type TSettingHandlerProps = {
  generators?: IDBSGenerator[];
  action?: 'generator_deleted' | 'generator_changed' | 'generator_date_changed';
  generator?: IDBSGenerator;
};

const GeneratorStepper = ({ formData, platform }: IOwnProps): JSX.Element => {
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [dialogMessage, setDialogMessage] = React.useState<string>('dbs.generatorChangeWarning');
  const [settingHandlerProps, setSettingHandlerProps] = React.useState<TSettingHandlerProps>({
    generators: undefined,
    action: undefined,
    generator: undefined,
  });

  const rightLead = formData.document?.leadRight;
  const leftLead = formData.document?.leadLeft;

  const creationMutator = (event: IDBSGenerator): IDBSGenerator => {
    if (!formData.document.generators || formData.document.generators?.length === 0) {
      return { ...event, firstGenerator: true, generatorDate: formData.document.date };
    } else {
      return event;
    }
  };

  const openDialog = (settingHandlerProps: TSettingHandlerProps) => (): void => {
    setSettingHandlerProps(settingHandlerProps);
    setDialogMessage(
      settingHandlerProps.action === 'generator_date_changed'
        ? 'vns.generatorDateChangeWarning'
        : 'vns.generatorChangeWarning',
    );
    setDialogOpen(true);
  };
  const cancelCallback = (): void => {
    settingHandlerProps.generators = formData.document.generators;
    setDialogOpen(false);
  };
  const confirmCallback = (): void => {
    if (settingHandlerProps.action) {
      generatorSettingHandler(formData, settingHandlerProps.action, settingHandlerProps.generator);
      formData.onChange?.({ generators: settingHandlerProps.generators });
    }
    setDialogOpen(false);
  };

  const onChangeGenerator =
    (editIndex: number) =>
    (values: TOnChangeValues): void => {
      const newGenerators = JSON.parse(JSON.stringify(formData.document.generators));
      const valuesName = Object.keys(values)[0]; // = generator
      const value = values[valuesName]; // = generator type
      newGenerators[editIndex] = { ...newGenerators[editIndex], [valuesName]: value };

      if (valuesName === 'generator') {
        // If a generator is changed, delete the settings linked to it
        if (formData.document.settings?.some((setting) => setting.generator === newGenerators[editIndex].id)) {
          openDialog({ generators: newGenerators, action: 'generator_changed', generator: newGenerators[editIndex] })();
        } else {
          formData.onChange?.({ generators: newGenerators });
        }
      }
    };

  const generators = formData.document.generators || [];
  const onChangeForm = formData.onChange;

  // Previous length to track deletions
  const [generatorsLength, setGeneratorsLength] = React.useState<number>(generators.length);

  React.useEffect(() => {
    if (generators.length === 0 && generatorsLength > 0) {
      // If last generator is deleted, clear generator settings
      onChangeForm && onChangeForm({ settings: [] });
    }
    if (generators.length < generatorsLength) {
      let status: 'ended' | 'suspended' | 'inProgress';
      // For defining whether to set status to 'suspended' or 'inProgress' after deleting a generator
      let discs = formData.document.discontinuationEvents;
      let gens = generators;

      // Sort both generators and discontinuation events
      gens = gens?.toSorted((n1, n2) => sortPartialDate(n1.generatorDate, n2.generatorDate)).reverse();
      discs = discs?.toSorted((n1, n2) => sortPartialDate(n1.date, n2.date)).reverse();
      // Find first eligible generator- and discontinuation objects with proper dates
      const firstGen = gens?.find((element) => element?.generatorDate?.[0] != null)?.generatorDate;
      const firstDisc = discs?.find((element) => element?.date?.[0] != null)?.date;
      // Depending on the dates, set status to 'suspended' or 'inProgress'
      if (firstGen?.[0] != null && firstDisc?.[0] != null) {
        if (partialDateToValue(firstGen) < partialDateToValue(firstDisc)) {
          status = 'suspended';
        } else {
          status = 'inProgress';
        }
      } else if (firstDisc?.[0] != null) {
        status = 'suspended';
      } else {
        status = 'inProgress';
      }

      onChangeForm && onChangeForm({ status: status });
    }

    setGeneratorsLength(generators.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generators.length]);

  return (
    <>
      <EventStepper
        name="generators"
        formData={formData}
        stepLabelText={(d: IDBSGenerator): string => formatPartialDate(d.generatorDate)}
        stepContent={(d: IDBSGenerator): JSX.Element => <GeneratorDetails generator={d} />}
        addNewTextHeader="dbs.newGenerator"
        addNewTextButton="dbs.newGenerator"
        latestEventTextHeader="dbs.currentGenerator"
        previousEventsTextHeader="dbs.previousGenerators"
        //separateLatest={true}
        buttonDisabled={generatorsUnavailable(formData.document, platform)}
        buttonDisabledMessage={fm(
          !(rightLead && leftLead && formData.document.targetNucleus)
            ? 'dbs.chooseLeadsAndTargetNucleusFirst'
            : 'dbs.noGeneratorsForChosenLeads',
        )}
        separateLatest={true}
        mutators={{ creationMutator }}
        dateFieldName="generatorDate"
        // If a generator is deleted, also delete the settings linked to it
        deleteExtraAction={(index: number, formData: IFormData<any, any>) => (): void => {
          generatorSettingHandler(formData, 'generator_deleted', formData.document.generators?.[index]);
        }}
        deleteMessage="dbs.generatorDeleteWarning"
        editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => (
          <React.Fragment>
            <div style={{ marginBottom: '2rem' }}>
              <InputHandler
                type="PartialDate"
                editing={formData.document.generators?.[index]?.firstGenerator ? false : true}
                name="generatorDate"
                formData={{
                  onChange,
                  document: { generatorDate: formData.document.generators?.[index]?.generatorDate || '' },
                }}
                // Default date to DBS starting date for first generator
                dateDefault={
                  formData.document.generators?.length === 1 && formData.document.date ? formData.document.date : 'now'
                }
                dateHook={{
                  dateHookFloor: formData.document.date,
                  dateHookCeiling: generatorDateHook(formData.document, index),
                }}
                isNotCancellable={true}
              />
            </div>
            <div style={{ marginBottom: '2rem' }}>
              <SelectByCategory
                type="SelectByCategory"
                editing={true}
                name="generator"
                onChange={onChangeGenerator(index)}
                value={formData.document.generators?.[index]?.generator || ''}
                options={rightLead && leftLead ? backwardsCompatibility(rightLead, leftLead, platform) : []}
                optionFormatter={optionFormatter}
                placeholder={fm('dbs.chooseGenerator')}
                fullWidth={false}
                formatTitle={true}
              />
            </div>
            <div style={{ marginBottom: '2rem' }}>
              <div style={{ color: colors.darkGray, marginBottom: '0.5rem' }}>{fm('dbs.generatorSerialNo')}</div>
              <InputHandler
                type="TextField"
                editing={true}
                name="generatorSerialNo"
                formData={{
                  onChange,
                  document: { generatorSerialNo: formData.document.generators?.[index]?.generatorSerialNo || '' },
                }}
                placeholder={'dbs.enterSerialNo'}
              />
            </div>
            <div style={{ marginBottom: '2rem' }}>
              <div style={{ color: colors.darkGray }}>{fm('dbs.nextChange')}</div>
              <InputHandler
                type="PartialDate"
                editing={true}
                name="generatorNextChangeDate"
                formData={{
                  onChange,
                  document: {
                    generatorNextChangeDate: formData.document.generators?.[index]?.generatorNextChangeDate || '',
                  },
                }}
                addToYearCeiling={10}
                dateHook={{ dateHookFloor: formData.document.generators?.[index]?.generatorDate }}
              />
            </div>
            <ConfirmationDialog
              open={dialogOpen}
              text={fm(dialogMessage)}
              confirm={{ callback: confirmCallback }}
              cancel={{ callback: cancelCallback }}
            />
          </React.Fragment>
        )}
      />
    </>
  );
};

interface IOwnProps {
  formData: IFormData<IDBS>;
  platform: string;
}

export default GeneratorStepper;
