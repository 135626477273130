import * as React from 'react';
import { formatPartialDate } from 'neuro-utils';
import { fm } from 'Components/FormatMessage';
import EventStepper from '../../../../../../components/EventStepper';
import InputHandler from '../../../../../../components/InputHandler';
import {
  StepperHeaderFormInputPair,
  StepperHeaderValuePair,
} from '../../../../../../components/EventStepper/components';
import { capitalize } from '../../../../../../utility/string';

const deviceTypeOptions = [
  'constantPressureCPAP',
  'selfAdjustingCPAP',
  'autosetForHer',
  'constantPressure2PV',
  'selfAdjusting2PV',
  'adaptiveServoVentilator',
  'automaticAdaptiveServoVentilator',
  'VAPS',
];

const deleteDeviceAction = (index: number, formData: IFormData<any, any>) => (): void => {
  const deviceId = formData.document.devices?.[index]?.id ?? undefined;
  const settings: Array<IPapSetting> = JSON.parse(JSON.stringify(formData.document.settings ?? []));
  const treatmentResponses: Array<IPapTreatmentResponse> = JSON.parse(
    JSON.stringify(formData.document.treatmentResponses ?? []),
  );
  if (deviceId && settings.length > 0) {
    const newSettings = settings.filter((s: IPapSetting) => s.deviceId !== deviceId);
    formData.onChange?.({ settings: newSettings });
  }
  if (deviceId && treatmentResponses.length > 0) {
    const newTreatmentResponses = treatmentResponses.filter((tr) => tr.deviceId !== deviceId);
    formData.onChange?.({ treatmentResponses: newTreatmentResponses });
  }
};

const Devices = ({ formData, disableCpapDevices, viewing }: IOwnProps): JSX.Element => {
  return (
    <EventStepper
      name="devices"
      formData={formData}
      stepLabelText={(d: IPapDevice): string => formatPartialDate(d.date)}
      stepContent={(d: IPapDevice): JSX.Element => (
        <React.Fragment>
          {(d.manufacturer === 'ResMed'
            ? ['type', 'manufacturer', 'deviceModel', 'resMedId']
            : ['type', 'manufacturer', 'deviceModel']
          ).map((key, index) => {
            const value = d[key as keyof IPapDevice];
            const k = key !== 'resMedId' && key !== 'deviceModel' ? `device${capitalize(key)}` : key;
            return (
              <StepperHeaderValuePair
                key={`${key}${index}`}
                header={fm(`treatment.papTherapy.${k}`)}
                value={
                  value
                    ? key !== 'resMedId' && key !== 'deviceModel'
                      ? fm(`treatment.papTherapy.opts.${value}`)
                      : value
                    : '-'
                }
              />
            );
          })}
        </React.Fragment>
      )}
      addNewTextHeader="treatment.papTherapy.newDevice"
      addNewTextButton="treatment.papTherapy.newDevice"
      previousEventsTextHeader="treatment.papTherapy.previousDevices"
      noPreviousEventsTextHeader="treatment.papTherapy.noPreviousDevices"
      editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => (
        <React.Fragment>
          <StepperHeaderFormInputPair
            header={fm('general.date')}
            input={
              <InputHandler
                type="PartialDate"
                editing={true}
                name="date"
                formData={{
                  onChange,
                  document: { date: formData.document.devices?.[index]?.date || '' },
                }}
                dateDefault={
                  formData.document.devices && formData.document.devices.length === 1 ? formData.document.date : 'now'
                }
                isNotCancellable={true}
                dateHook={{
                  dateHookFloor: formData.document.date,
                }}
              />
            }
          />
          <StepperHeaderFormInputPair
            header={fm('treatment.papTherapy.deviceType')}
            input={
              <InputHandler
                type="Radio"
                editing={true}
                name="type"
                formData={{
                  onChange,
                  document: { type: formData.document.devices?.[index]?.type || '' },
                }}
                options={deviceTypeOptions}
                optionFormatter={(name: string | number): JSX.Element => fm(`treatment.papTherapy.opts.${name}`)}
                // If there are any settings or tr's linked to the device, disable changing the device type.
                disabledOptions={
                  formData.document.settings?.find((s) => s.deviceId === formData.document.devices?.[index].id) ||
                  formData.document.treatmentResponses?.find(
                    (tr) => tr.deviceId === formData.document.devices?.[index]?.id,
                  )
                    ? deviceTypeOptions
                    : disableCpapDevices
                      ? ['constantPressureCPAP', 'selfAdjustingCPAP', 'autosetForHer']
                      : undefined
                }
              />
            }
          />
          <StepperHeaderFormInputPair
            header={fm('treatment.papTherapy.deviceManufacturer')}
            input={
              <InputHandler
                type="Radio"
                editing={true}
                name="manufacturer"
                formData={{
                  onChange,
                  document: { manufacturer: formData.document.devices?.[index]?.manufacturer || '' },
                }}
                options={['ResMed', 'other']}
                optionFormatter={(name: string | number): JSX.Element => fm(`treatment.papTherapy.opts.${name}`)}
                dependentFieldsList={(): string[] => ['resMedId']}
              />
            }
          />
          <StepperHeaderFormInputPair
            header={fm('treatment.papTherapy.deviceModel')}
            input={
              <InputHandler
                type="TextField"
                editing={true}
                name="deviceModel"
                placeholder={'treatment.papTherapy.deviceModel'}
                formData={{
                  onChange,
                  document: { deviceModel: formData.document.devices?.[index]?.deviceModel || '' },
                }}
              />
            }
          />
          {formData.document.devices?.[index].manufacturer === 'ResMed' && (
            <StepperHeaderFormInputPair
              header={fm('treatment.papTherapy.resMedId')}
              input={
                <InputHandler
                  type="TextField"
                  editing={true}
                  name="resMedId"
                  formData={{
                    onChange,
                    document: {
                      resMedId: formData.document.devices?.[index]?.resMedId || '',
                    },
                  }}
                />
              }
            />
          )}
        </React.Fragment>
      )}
      viewing={viewing}
      deleteMessage="treatment.papTherapy.deleteDevice"
      deleteExtraAction={deleteDeviceAction}
    />
  );
};

interface IOwnProps {
  formData: IFormData<IPAPTherapy>;
  disableCpapDevices: boolean;
  viewing: boolean;
}

export default Devices;
