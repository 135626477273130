import * as React from 'react';
import TabContent from 'Components/TabContent';
import { convertTreatmentEfficiencyToRatingFormat } from 'Utility/ninmtUtil';
import PatientsRating from '../PatientsRating';
import { styled } from '@mui/system';
import colors from '../../../../../../../config/theme/colors';
import { TDCSContext } from 'Routes/Tdcs/Document';
import { partialDateToValue } from 'neuro-utils';

const EmptyTabContent = styled('div')({
  margin: '0rem 0rem 0rem 0rem',
  padding: '2rem 2rem 2rem 2rem',
  marginRight: '0rem',
  border: `1px solid ${colors.gray}`,
  backgroundColor: colors.white,
});

const PatientsRatings = ({
  editIndex,
  editingEvent = true,
  formData,
  fm,
  symptomsAndLocations,
  sessionDate,
  initializePatientsRating,
}: {
  editIndex: number;
  editingEvent?: boolean;
  formData: IFormData<ITDCS>;
  fm: (m: string) => string;
  symptomsAndLocations: Array<ISymptomsAndLocations>;
  sessionDate?: PartialDate;
  initializePatientsRating?: (index: number, date?: PartialDate) => void;
}): JSX.Element => {
  const tdcsContext = React.useContext(TDCSContext);
  const { sortedAndMergedMyDocuments, patientMySQStatus } = tdcsContext;

  const treatmentEfficiencyDocs = sortedAndMergedMyDocuments.filter(
    (d) => d._type === 'treatmentEfficiency' && partialDateToValue(d.date) === partialDateToValue(sessionDate),
  ) as ITreatmentEfficiency[];

  const before = (treatmentEfficiencyDocs ?? []).find((d) => d.timing === 'before');
  const after = (treatmentEfficiencyDocs ?? []).find((d) => d.timing === 'after');

  const { document, onChange } = formData;

  const patientsRating = document.sessions?.[editIndex].patientsRating;

  // For treatment efficiency values
  const [treatmentEfficiencyRating, setTreatmentEfficiencyRating] = React.useState<Array<ITDCSRating> | undefined>(
    undefined,
  );

  // Initialize/update patient's rating or treatment efficiency values
  React.useEffect(() => {
    if (!patientMySQStatus && !patientsRating && editingEvent && initializePatientsRating) {
      initializePatientsRating(editIndex);
      return;
    }
    if ((before || after) && !patientsRating) {
      setTreatmentEfficiencyRating(
        convertTreatmentEfficiencyToRatingFormat(before && after ? [before, after] : before ? before : after!),
      );
    }
  }, [sessionDate, symptomsAndLocations]);

  const usedRating =
    Array.isArray(patientsRating) && patientsRating.filter((s) => s).length > 0
      ? patientsRating
      : treatmentEfficiencyRating
        ? treatmentEfficiencyRating
        : [];

  // onChange for individual patient's ratings
  const patientsRatingOnChange =
    (index: number) =>
    (value: TOnChangeValues): void => {
      // Check if there exists an patient's rating with given index
      if (Array.isArray(patientsRating) && patientsRating.length >= index) {
        const newSessions = JSON.parse(JSON.stringify(document.sessions));
        const newPatientsRating = JSON.parse(JSON.stringify(patientsRating));
        newPatientsRating[index] = Object.assign({}, newPatientsRating[index], value);
        newSessions[editIndex].patientsRating = newPatientsRating;
        onChange && onChange({ sessions: newSessions });
      }
    };

  return Array.isArray(usedRating) && usedRating.length > 0 ? (
    <TabContent>
      {
        usedRating?.map((s: ITDCSRating, i: number) => ({
          key: `${s.id}${i}`,
          id: `${s.id}Tab${i}`,
          title: s.symptom ? fm(`tdcs.patientsRating.opts.${s.symptom}`) : fm('tdcs.patientsRating.symptomPlaceholder'),
          content: (
            <PatientsRating
              document={s}
              onChange={patientsRatingOnChange(i)}
              editingEvent={!patientsRating ? false : editingEvent}
            />
          ),
          count: i,
        })) as []
      }
    </TabContent>
  ) : (
    <EmptyTabContent>
      {fm(
        !patientsRating && !treatmentEfficiencyRating ? 'general.noData' : 'tdcs.patientsRating.noSymptomsAndLocations',
      )}
    </EmptyTabContent>
  );
};

export default PatientsRatings;
