import * as React from 'react';

import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import FormEditingHandler from '../../../containers/FormEditingHandler';
import HistoryRow from '../../../components/HistoryRow';
import CollapseElem from '../../../components/Collapse';
import HistoryRowControls from '../../../components/HistoryRowControls';
import DocumentCreationButton from '../../../components/DocumentCreationButton';

import { formatPartialDate, sortPartialDate } from 'neuro-utils';

import HistoryRowData from './HistoryRowDataNew';
import BackgroundFormDynamic from './FormDynamic';
import HistoryTabs from 'Components/HistoryTabs';
import CareTableHistory from './CareTableHistory';
import MeasurementFormMultiple, { INewMeasurements } from './FormMeasurement/multiple';
import { TDispatch, useAppSelector as useSelector } from 'Store/index';
import { useDispatch } from 'react-redux';
import { actions as myAppActions } from 'Store/myapp/actions';
import StandAloneFormEditingHandler from 'Containers/StandAloneFormEditingHandler';
import { actions, TCreationPromiseType } from 'Store/documents/actions';
import { IMgravisBackground, IParkinsonMobileBackground } from 'neuro-schemas';

const documentType = (documents: IControlProps[], id: string) => documents.find((d) => d._id === id)?._type;

const saveNewMeasurementDocs = (newMeasurements: INewMeasurements, dispatch: TDispatch) => {
  const creationPromises: Promise<TCreationPromiseType>[] = [];
  if (newMeasurements.date) {
    newMeasurements.values?.forEach((value) => {
      if (!value.code || !value.value) return;
      const measurementData = {
        date: newMeasurements.date,
        time: newMeasurements.time,
        code: value.code,
        value: value.value,
      };
      creationPromises.push(actions.putDocument('measurement', measurementData)(dispatch));
    });
  }
  return Promise.all(creationPromises);
};

const Background = ({ documents }: IOwnProps): JSX.Element => {
  const [openTab, setOpenTab] = React.useState<number>(0);

  const users = useSelector((state) => state.session.orgUsers);

  // My background documents
  const myBgDocs =
    useSelector((s: IState) => s.myapp.sortedAndMergedDocuments)?.filter((d) =>
      d._type.toLowerCase().includes('background'),
    ) ?? [];
  const backgroundDocuments = documents.filter((d) => d._type === 'background').concat(myBgDocs) as IBackground[];

  const careTableDocuments = documents.filter((d) => d._type === 'measurement') as Array<IMeasurement>;
  const myCareTableDocs = myBgDocs.filter((d) => 'height' in d || 'weight' in d || 'bmi' in d);

  const sortedBackgroundDocuments = backgroundDocuments
    ? backgroundDocuments
        ?.slice()
        .sort((n1, n2) => n2._cdate - n1._cdate)
        .sort((n1, n2) => sortPartialDate(n2.date, n1.date))
    : [];

  const platform = useSelector((s: IState) => s.session.platforms?.selected);

  // My document handling
  const [sharedBgFormOpen, setSharedBgFormOpen] = React.useState<
    | {
        doc: (ISRMobileBackground | IParkinsonMobileBackground | IMgravisBackground) & IControlProps;
        type: 'edit' | 'view';
      }
    | undefined
  >(undefined);
  const [tempSharedBg, setTempSharedBg] = React.useState<
    ((ISRMobileBackground | IParkinsonMobileBackground | IMgravisBackground) & IControlProps) | undefined
  >(undefined);
  const [saving, setSaving] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  const saveSharedBg = async () => {
    setSaving(true);
    await myAppActions.putNewCommit(
      sharedBgFormOpen?.doc._type ?? '',
      sharedBgFormOpen?.doc._id ?? '',
      tempSharedBg,
      dispatch,
    );
    setSaving(false);
    setSharedBgFormOpen(undefined);
  };

  const handleSharedBgFormChange = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];
    if (name && (value || value === 0)) {
      setTempSharedBg((prev) => ({
        ...(prev as (ISRMobileBackground | IParkinsonMobileBackground | IMgravisBackground) & IControlProps),
        [name]: value,
      }));
    }
  };

  const handleMyBackgroundDelete = async (myBackgroundId: string) => {
    const docType = myBgDocs.find((d) => d._id === myBackgroundId)?._type ?? 'background';
    await myAppActions.deleteDocument(docType, myBackgroundId, dispatch);
  };

  React.useEffect(() => {
    if (sharedBgFormOpen && sharedBgFormOpen.doc) {
      setTempSharedBg(structuredClone(sharedBgFormOpen.doc));
    }
  }, [sharedBgFormOpen]);

  const isBackgroundDocumentShared = (d: IBackground): boolean => {
    // Note: Alternatively, it's possible that creatorId should match patientId of current session
    if (myBgDocs.some((sharedDoc) => sharedDoc._id === d._id)) return true;
    return false;
  };

  // New measurements data
  const [measurementNewData, setMeasurementNewData] = React.useState<INewMeasurements | null>(null);
  const measurementNewDataOnChange = (values: TOnChangeValues) => {
    setMeasurementNewData({ ...measurementNewData, ...values });
  };

  return (
    <React.Fragment>
      {(openTab === 0 && measurementNewData) || (openTab === 1 && sharedBgFormOpen) ? (
        <StandAloneFormEditingHandler
          currentPageName="background"
          headerLocaleId={measurementNewData ? 'background.addCareData' : 'background.title'}
          loading={saving}
          cancelEditingAction={() => {
            if (measurementNewData) {
              setMeasurementNewData(null);
            }
            if (sharedBgFormOpen) {
              setSharedBgFormOpen(undefined);
            }
          }}
          saveAction={() => {
            setSaving(true);
            if (measurementNewData) {
              saveNewMeasurementDocs(measurementNewData, dispatch).then(() => {
                setSaving(false);
                setMeasurementNewData(null);
              });
            }
            if (sharedBgFormOpen) {
              saveSharedBg();
            }
          }}
          viewing={sharedBgFormOpen?.type === 'view'}
          endViewingAction={() => setSharedBgFormOpen(undefined)}
        >
          <>
            {measurementNewData && (
              <MeasurementFormMultiple
                formData={{ onChange: measurementNewDataOnChange, document: measurementNewData }}
              />
            )}
            {sharedBgFormOpen && (
              <BackgroundFormDynamic
                formData={{
                  onChange: handleSharedBgFormChange,
                  document: (tempSharedBg ?? {}) as IFormData['document'],
                }}
                documents={backgroundDocuments || []}
                users={users ?? []}
                isShared
              />
            )}
          </>
        </StandAloneFormEditingHandler>
      ) : (
        <FormEditingHandler name="background" documents={documents}>
          {(editing, startEdit, formData): JSX.Element => {
            const startEditFunction = (document: any) =>
              isBackgroundDocumentShared(document)
                ? () =>
                    setSharedBgFormOpen({
                      doc: document,
                      type: 'edit',
                    })
                : startEdit(document, 'background');

            return (
              <DocumentWrapper>
                <DocumentHeader
                  name={'background'}
                  headerId={
                    editing && documentType(documents, editing) === 'background'
                      ? 'background.addBasicInformationDocument'
                      : editing && documentType(documents, editing) === 'measurement'
                        ? 'background.addCareData'
                        : 'background.title'
                  }
                  editing={editing}
                  editButtons={
                    <DocumentCreationButton
                      name="background"
                      text={
                        openTab === 0
                          ? 'background.addCareData'
                          : openTab === 1
                            ? 'background.addBasicInformationDocument'
                            : 'general.new'
                      }
                      onClick={
                        openTab === 0
                          ? () => setMeasurementNewData({})
                          : openTab === 1
                            ? startEdit({}, 'background')
                            : undefined
                      }
                    />
                  }
                />
                <React.Fragment>
                  {editing && documentType(documents, editing) === 'background' && (
                    <BackgroundFormDynamic
                      formData={formData}
                      documents={backgroundDocuments || []}
                      users={users ?? []}
                    />
                  )}
                  {!editing && (
                    <HistoryTabs indexSelectionTools={{ index: openTab, changeFunction: (index) => setOpenTab(index) }}>
                      {[
                        {
                          title: 'background.careTable',
                          content: <CareTableHistory documents={careTableDocuments} myDocuments={myCareTableDocs} />,
                        },
                        ...(platform === 'ninmt'
                          ? []
                          : [
                              {
                                title: 'background.basicInformation',
                                content:
                                  sortedBackgroundDocuments.length > 0 ? (
                                    <React.Fragment>
                                      <HistoryRow
                                        headerText={
                                          sortedBackgroundDocuments[0].date
                                            ? formatPartialDate(sortedBackgroundDocuments[0].date)
                                            : undefined
                                        }
                                        key={sortedBackgroundDocuments[0]._id}
                                        rowButton={
                                          <HistoryRowControls
                                            document={sortedBackgroundDocuments[0]}
                                            startEdit={startEditFunction}
                                            enableButtons={isBackgroundDocumentShared(sortedBackgroundDocuments[0])}
                                            deleteHandler={
                                              isBackgroundDocumentShared(sortedBackgroundDocuments[0])
                                                ? () => handleMyBackgroundDelete(sortedBackgroundDocuments[0]._id)
                                                : undefined
                                            }
                                          />
                                        }
                                        controlsMargin={false}
                                      >
                                        <HistoryRowData
                                          documents={sortedBackgroundDocuments}
                                          d={sortedBackgroundDocuments[0]}
                                          users={users ?? []}
                                        />
                                      </HistoryRow>
                                      {sortedBackgroundDocuments.length > 1 && (
                                        <CollapseElem
                                          localeIDs={{
                                            showMessage: 'background.showPastDocuments',
                                            hideMessage: 'background.hidePastDocuments',
                                          }}
                                          amount={sortedBackgroundDocuments.slice(1).length}
                                        >
                                          {sortedBackgroundDocuments.slice(1).map((d: IBackground) => (
                                            <HistoryRow
                                              headerText={d.date ? formatPartialDate(d.date) : undefined}
                                              key={d._id}
                                              rowButton={
                                                <HistoryRowControls
                                                  document={d}
                                                  startEdit={startEditFunction}
                                                  enableButtons={isBackgroundDocumentShared(d)}
                                                  deleteHandler={
                                                    isBackgroundDocumentShared(d)
                                                      ? () => handleMyBackgroundDelete(d._id)
                                                      : undefined
                                                  }
                                                />
                                              }
                                              controlsMargin={false}
                                            >
                                              <HistoryRowData
                                                documents={sortedBackgroundDocuments}
                                                d={d}
                                                users={users ?? []}
                                              />
                                            </HistoryRow>
                                          ))}
                                        </CollapseElem>
                                      )}
                                    </React.Fragment>
                                  ) : (
                                    <></>
                                  ),
                              },
                            ]),
                      ]}
                    </HistoryTabs>
                  )}
                </React.Fragment>
              </DocumentWrapper>
            );
          }}
        </FormEditingHandler>
      )}
    </React.Fragment>
  );
};

interface IOwnProps {
  documents: Array<IMeasurement | IBackground>;
}

export default Background;
