import { clone, intersperse, values } from 'ramda';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';

import { StepperHeaderValuePair } from '../../../../../../../components/EventStepper/components';
import { Container, Item } from '../../../../../../../components/Grid';

import { exists, formatTime, isTime } from 'neuro-utils';
import { isParkinsonInfusionType } from '../utils/isParkinsonInfusionType';
import Unit from '../../../../../../../components/Unit';
import MedicationUnit from 'Routes/Medication/utils/medicationUnit';

const StyledValueArea = styled('div')({
  marginBottom: '3rem',
});

const InlineDiv = styled('div')({
  display: 'inline-flex',
});

const getStrengthValue = (s?: Array<IStrengths>): number =>
  !s || s.length === 0 ? 1 : parseInt(s[0][Object.keys(s[0])[0]]);

export const StrengthsItem = ({ strengths }: { strengths?: Array<IStrengths> }): JSX.Element => {
  const strs = Array.isArray(strengths) ? strengths.map((s) => intersperse(' / ', values(s))) : [];
  return (
    <React.Fragment>
      {strs.map((s) => (
        <div key={s.toString()}>{s}</div>
      ))}
    </React.Fragment>
  );
};

const DoseItem = ({ dose }: { dose: number }): JSX.Element => (
  <div>
    <span style={{ fontWeight: 600 }}>
      <FormattedMessage id="medication.regimenDose" values={{ n: dose }} />
    </span>
  </div>
);

export const RegimenOnDemand = ({ r }: { r: Regimen }): JSX.Element => {
  const regimen: IRegimenBasics & IRegimenOnDemand = clone(r as IRegimenBasics & IRegimenOnDemand);
  return (
    <StyledValueArea>
      <StepperHeaderValuePair
        header={<FormattedMessage id="medication.regimenType" />}
        value={<FormattedMessage id={`medication.opts.${regimen.regimenType}`} />}
      />
      <StepperHeaderValuePair
        header={<FormattedMessage id="medication.routeOfAdministration" />}
        value={
          regimen.routeOfAdministration && (
            <FormattedMessage id={`medication.opts.mr.${regimen.routeOfAdministration}`} />
          )
        }
      />
      <StepperHeaderValuePair
        header={<FormattedMessage id="medication.strength" />}
        value={regimen.strengths && <StrengthsItem strengths={regimen.strengths} />}
      />
      <StepperHeaderValuePair
        header={<FormattedMessage id="medication.doseSize" />}
        value={
          exists(regimen.dose) ? (
            <span>
              {regimen.dose + ' '}
              <MedicationUnit unit={regimen.unit} />
            </span>
          ) : (
            '-'
          )
        }
      />
      <StepperHeaderValuePair
        header={<FormattedMessage id="medication.maxDoses" />}
        value={
          exists(regimen.maxDoses) ? (
            <span>
              {regimen.maxDoses + ' '}
              <MedicationUnit unit={regimen.unit} />
            </span>
          ) : (
            '-'
          )
        }
      />
      <StepperHeaderValuePair
        header={<FormattedMessage id="medication.regimenDetailsOther" />}
        value={regimen?.regimenDetailsOther || '-'}
      />
      <br />
    </StyledValueArea>
  );
};

export const getStrengthIndex = (s: IStrengths, pad?: boolean): string => {
  const strs = values(s);

  return pad ? strs.join(' / ') : strs.join('/');
};

export const timesAndDosages = (
  dosages: Array<IDosage>,
  regimen: IRegimenBasics & IRegimenDefault,
  strengths: Array<IStrengths>,
  xs?: number | boolean,
): Array<{ from: string; to: string; dosages: Array<JSX.Element> }> => {
  if (dosages.length > 0) {
    const arr = dosages
      .map((d) => ({
        from: formatTime(d.from),
        to: formatTime(d.to),
        dosages: strengths.map(
          (s: IStrengths, i: number): JSX.Element => (
            <Item style={{ textAlign: 'right' }} xs={xs} key={i}>
              {exists(d.dosages?.[getStrengthIndex(s)]) ? d.dosages?.[getStrengthIndex(s)] : '-'}
              {exists(d.dosages?.[getStrengthIndex(s)]) && regimen.unit ? (
                <span>
                  {' '}
                  <MedicationUnit unit={regimen.unit} />
                </span>
              ) : (
                ''
              )}
            </Item>
          ),
        ),
      }))
      .sort((a, b) => (a.from < b.from ? -1 : 0));
    return arr;
  } else return [];
};

export const RegimenDefault = ({ r }: { r: Regimen }): JSX.Element => {
  const regimen: IRegimenBasics & IRegimenDefault = clone(r as IRegimenBasics & IRegimenDefault);

  const dosages = regimen.dosages || [];
  const strengths = regimen.strengths || [];

  const hasTimes = !!dosages.find((d) => isTime(d.from));

  return (
    <StyledValueArea>
      <StepperHeaderValuePair
        header={<FormattedMessage id="medication.regimenType" />}
        value={<FormattedMessage id={`medication.opts.${regimen.regimenType}`} />}
      />
      <StepperHeaderValuePair
        header={<FormattedMessage id="medication.routeOfAdministration" />}
        value={
          regimen.routeOfAdministration && (
            <FormattedMessage id={`medication.opts.mr.${regimen.routeOfAdministration}`} />
          )
        }
      />
      <StepperHeaderValuePair
        header={<FormattedMessage id="medication.doses.strengths" />}
        value={<StrengthsItem strengths={strengths} />}
      />
      {dosages.length > 0 ? (
        <>
          <StepperHeaderValuePair
            header={<FormattedMessage id="medication.regimenDosage" />}
            value={
              <>
                <Container style={{ fontWeight: 400 }}>
                  {hasTimes && (
                    <Item xs={3}>
                      <FormattedMessage id="medication.doses.time" />
                    </Item>
                  )}

                  {strengths.map((s) => (
                    <Item style={{ textAlign: 'right' }} key={getStrengthIndex(s)} xs={true}>
                      {getStrengthIndex(s, true)}
                    </Item>
                  ))}
                </Container>
                {dosages.map((d, i) => (
                  <Container key={i}>
                    {hasTimes && (
                      <Item xs={3}>
                        {timesAndDosages(dosages, regimen, strengths, true)[i].from}
                        {timesAndDosages(dosages, regimen, strengths, true)[i].to ? ' - ' : ''}
                        {timesAndDosages(dosages, regimen, strengths, true)[i].to
                          ? timesAndDosages(dosages, regimen, strengths, true)[i].to
                          : ''}
                      </Item>
                    )}
                    {timesAndDosages(dosages, regimen, strengths, true)[i].dosages}
                  </Container>
                ))}
              </>
            }
          />
          <StepperHeaderValuePair
            header={<FormattedMessage id="medication.doses.period" />}
            value={
              <>
                {regimen.periodNumber && regimen.periodUnit && regimen.periodUnit !== undefined ? (
                  <>
                    <FormattedMessage id="medication.doses.every" />{' '}
                    {regimen.periodNumber === 1 ? '' : regimen.periodNumber}
                    {regimen.periodNumber === 1 ? ' ' : '. '}
                    <span style={{ textTransform: 'lowercase' }}>
                      <FormattedMessage id={`medication.doses.periodOpts.${regimen.periodUnit}`} />
                    </span>
                  </>
                ) : regimen.periodNumber === null ||
                  (regimen.periodNumber === undefined && regimen.periodUnit && regimen.periodUnit !== undefined) ? (
                  <FormattedMessage id={`medication.doses.periodOpts.${regimen.periodUnit}`} />
                ) : (
                  '-'
                )}
              </>
            }
          />
        </>
      ) : (
        <StepperHeaderValuePair
          header={<FormattedMessage id="medication.doses.title" />}
          value={
            <span style={{ textTransform: 'lowercase' }}>
              {regimen?.doses ? (
                <>
                  {regimen.unit ? (
                    <span>
                      {regimen.doses} <MedicationUnit unit={regimen.unit} />
                    </span>
                  ) : (
                    <FormattedMessage id="medication.regimenDose" values={{ n: regimen?.doses }} />
                  )}
                  {regimen?.repeatNumber && (
                    <>
                      {` `}
                      <FormattedMessage id="medication.regimenDoseInterval" values={{ n: regimen?.repeatNumber }} />
                    </>
                  )}
                </>
              ) : (
                !(regimen?.doses || regimen?.interval) && '-'
              )}
              &nbsp;
              {regimen?.interval && <FormattedMessage id={`medication.opts.${regimen?.interval}`} />}
            </span>
          }
        />
      )}
      <StepperHeaderValuePair
        header={<FormattedMessage id="medication.regimenDetailsOther" />}
        value={regimen?.regimenDetailsOther || '-'}
      />
    </StyledValueArea>
  );
};

export const RegimenCustom = ({ r, d }: { r: Regimen; d?: IMedication }): JSX.Element => {
  const regimen: IRegimenBasics & IRegimenCustom = clone(r as IRegimenBasics & IRegimenCustom);

  const transformToMlhValue = (mgh?: number): number | undefined =>
    mgh ? mgh / getStrengthValue(regimen.strengths) : undefined;

  const parkinsonCustomFieldType = d && isParkinsonInfusionType(d);

  const parkinsonCustomElements = (
    <>
      {parkinsonCustomFieldType === 'gel' && regimen.infusionDoses?.morningDose && (
        <>
          <div style={{ fontWeight: 'normal' }}>
            <FormattedMessage id="medication.regimenMorningDose" />
          </div>
          <Container style={{ marginBottom: '1rem' }}>
            <Item xs={6}>
              <Container>
                <Item xs={12}>
                  <InlineDiv>
                    <Unit unit={<FormattedMessage id="medication.regimenInfusion_ml" />}>
                      {transformToMlhValue(regimen.infusionDoses?.morningDose) ?? '-'}
                    </Unit>
                  </InlineDiv>
                  <InlineDiv>
                    <Unit
                      unit={
                        <span>
                          <FormattedMessage id="medication.regimenInfusion_mg" />
                          {')'}
                        </span>
                      }
                    >
                      {'(' + (regimen.infusionDoses?.morningDose ?? '-')}
                    </Unit>
                  </InlineDiv>
                </Item>
              </Container>
            </Item>
          </Container>
        </>
      )}
      {regimen.infusionDoses?.upkeepDoses &&
        regimen.infusionDoses.upkeepDoses.map((upkeepDose, i) => {
          return (
            <React.Fragment key={`${upkeepDose.upkeepDose}${upkeepDose.upkeepDoseFrom}${upkeepDose.upkeepDoseTo}${i}`}>
              <div style={{ fontWeight: 'normal' }}>
                <FormattedMessage id="medication.regimenUpkeepDose" />
              </div>
              <Container style={{ marginBottom: '1rem' }}>
                <Item xs={4}>
                  <InlineDiv>
                    <Unit unit={<FormattedMessage id="medication.regimenInfusion_mlPerHour" />}>
                      {transformToMlhValue(upkeepDose.upkeepDose) ?? '-'}
                    </Unit>
                  </InlineDiv>
                  <InlineDiv>
                    <Unit
                      unit={
                        <span>
                          <FormattedMessage id="medication.regimenInfusion_mgPerHour" />
                          {')'}
                        </span>
                      }
                    >
                      {'(' + (upkeepDose.upkeepDose ?? '-')}
                    </Unit>
                  </InlineDiv>
                </Item>
                <Item style={{ fontWeight: 400, padding: '0 1rem' }}>
                  <FormattedMessage id="general.clock" />
                </Item>
                <Item>
                  <Unit unit={<FormattedMessage id="medication.regimenInfusion_mgPerHourStarting" />}>
                    {formatTime(upkeepDose.upkeepDoseFrom)}
                  </Unit>
                </Item>
                <Item>
                  <Unit unit={<FormattedMessage id="medication.regimenInfusion_mgPerHourEnding" />}>
                    {formatTime(upkeepDose.upkeepDoseTo)}
                  </Unit>
                </Item>
              </Container>
            </React.Fragment>
          );
        })}
      {regimen.infusionDoses?.additionalDose && (
        <>
          <div style={{ fontWeight: 'normal' }}>
            <FormattedMessage id="medication.regimenAdditionalDose" />
          </div>
          <Container>
            <Item xs={4}>
              <InlineDiv>
                <Unit unit={<FormattedMessage id="medication.regimenInfusion_ml" />}>
                  {transformToMlhValue(regimen.infusionDoses?.additionalDose) ?? '-'}
                </Unit>
              </InlineDiv>
              <InlineDiv>
                <Unit
                  unit={
                    <span>
                      <FormattedMessage id="medication.regimenInfusion_mg" />
                      {')'}
                    </span>
                  }
                >
                  {'(' + (regimen.infusionDoses?.additionalDose ?? '-')}
                </Unit>
              </InlineDiv>
            </Item>
            <Item style={{ padding: '0 4rem' }} xs={8}>
              <Unit unit={<FormattedMessage id="medication.regimenInfusion_timesPerDay" />}>
                {regimen.infusionDoses?.additionalDoseLimit ?? ''}
              </Unit>
            </Item>
          </Container>
        </>
      )}
    </>
  );

  return (
    <StyledValueArea>
      <StepperHeaderValuePair
        header={<FormattedMessage id="medication.regimenType" />}
        value={<FormattedMessage id={`medication.opts.${regimen.regimenType}`} />}
      />
      <StepperHeaderValuePair
        header={<FormattedMessage id="medication.routeOfAdministration" />}
        value={
          regimen.routeOfAdministration && (
            <FormattedMessage id={`medication.opts.mr.${regimen.routeOfAdministration}`} />
          )
        }
      />
      <StepperHeaderValuePair
        header={<FormattedMessage id="medication.strength" />}
        value={regimen.strengths && <StrengthsItem strengths={regimen.strengths} />}
      />

      {parkinsonCustomFieldType && (
        <StepperHeaderValuePair
          header={<FormattedMessage id="medication.regimenDosage" />}
          value={parkinsonCustomElements}
        />
      )}
      <StepperHeaderValuePair
        header={<FormattedMessage id="medication.regimenDetails" />}
        value={regimen.regimenDetails}
      />
      <StepperHeaderValuePair
        header={<FormattedMessage id="medication.regimenDetailsOther" />}
        value={regimen?.regimenDetailsOther || '-'}
      />
    </StyledValueArea>
  );
};

export const RegimenSingleDose = ({ r }: { r: Regimen }): JSX.Element => {
  const regimen: IRegimenBasics & IRegimenSingleDose = clone(r as IRegimenBasics & IRegimenSingleDose);
  return (
    <StyledValueArea>
      <StepperHeaderValuePair
        header={<FormattedMessage id="medication.regimenType" />}
        value={<FormattedMessage id={`medication.opts.${regimen.regimenType}`} />}
      />
      <StepperHeaderValuePair
        header={<FormattedMessage id="medication.routeOfAdministration" />}
        value={
          regimen.routeOfAdministration && (
            <FormattedMessage id={`medication.opts.mr.${regimen.routeOfAdministration}`} />
          )
        }
      />
      <StepperHeaderValuePair
        header={<FormattedMessage id="medication.strength" />}
        value={regimen.strengths && <StrengthsItem strengths={regimen.strengths} />}
      />
      <StepperHeaderValuePair
        header={<FormattedMessage id="medication.regimenDosage" />}
        value={
          regimen.dose && regimen.unit ? (
            <span>
              {regimen.dose} <MedicationUnit unit={regimen.unit} />
            </span>
          ) : regimen.dose ? (
            <DoseItem dose={regimen.dose} />
          ) : undefined
        }
      />
      <StepperHeaderValuePair
        header={<FormattedMessage id="medication.regimenDetailsOther" />}
        value={regimen?.regimenDetailsOther || '-'}
      />
    </StyledValueArea>
  );
};
export const RegimenOther = ({ r }: { r: Regimen }): JSX.Element => {
  const regimen: IRegimenBasics & IRegimenOther = clone(r as IRegimenBasics & IRegimenOther);
  return (
    <StyledValueArea>
      <StepperHeaderValuePair
        header={<FormattedMessage id="medication.regimenType" />}
        value={<FormattedMessage id={`medication.opts.${regimen.regimenType}`} />}
      />
      <StepperHeaderValuePair
        header={<FormattedMessage id="medication.routeOfAdministration" />}
        value={
          regimen.routeOfAdministration && (
            <FormattedMessage id={`medication.opts.mr.${regimen.routeOfAdministration}`} />
          )
        }
      />
      <StepperHeaderValuePair
        header={<FormattedMessage id="medication.strength" />}
        value={regimen.strengths && <StrengthsItem strengths={regimen.strengths} />}
      />
      <StepperHeaderValuePair
        header={<FormattedMessage id="medication.regimenOtherInstructions" />}
        value={regimen?.instructions || '-'}
      />
      <StepperHeaderValuePair
        header={<FormattedMessage id="medication.regimenDetailsOther" />}
        value={regimen?.regimenDetailsOther || '-'}
      />
    </StyledValueArea>
  );
};
