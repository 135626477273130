import { intersection } from 'ramda';
import { path } from 'Utility/ramdaReplacement';

export const drawSiblingDiagnosisAge = (document: IBackground | { [field: string]: any }): boolean => {
  const field: string[] =
    path(['familyMembersParkinson', 'value'], document) || path(['familyMembersParkinson'], document) || [];
  return intersection(field ?? [], ['siblings', 'twinSibling', 'twinSister', 'twinBrother']).length > 0;
};
export const drawOccupation = (document: IBackground | { [field: string]: any }): boolean => {
  const field: string[] = path(['employment', 'value'], document) || path(['employment'], document) || [];
  return intersection(field ?? [], ['employed', 'fullTime', 'partTime']).length > 0;
};
export const drawSickLeaveDays = (document: IBackground | { [field: string]: any }): boolean => {
  const field: string[] = path(['employment', 'value'], document) || path(['employment'], document) || [];
  return intersection(field ?? [], ['sickLeave']).length > 0;
};

/**
 * Generalized drawSpecificRow function
 * @param document HistoryDocument of background (single or multiple merged together with fields having 'date' and 'value' subfields)
 * @param condition `conditionField` to be checked to have one of the `value` in the document
 * @returns {boolean} of the condition met
 * e.g. drawOccupation() would be called as drawRow(doc, { conditionField: 'employment', value: ['employed', 'fullTime', 'partTime'] })
 * Function checks only if one or more of the condition value is found.
 */
export const drawRow = (
  document: IBackground | { [field: string]: any },
  condition: { conditionField: string; value: string | number | Array<string | number> },
): boolean => {
  const field = path([condition.conditionField, 'value'], document) || path([condition.conditionField], document) || [];
  return (
    intersection(
      Array.isArray(field) ? field : field || field === 0 ? [field] : [],
      Array.isArray(condition.value) ? condition.value : [condition.value],
    ).length > 0
  );
};
